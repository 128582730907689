const navbarItems = [
  {
    name: "Accueil",
    path: "/",
    active: true,
  },
  {
    name: "Découvrir",
    path: "/",
    active: false,
  },
  {
    name: "En ce moment",
    path: "/",
    active: false,
  },
];

export default navbarItems;
